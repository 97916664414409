import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Input from "./items/Input"
import Textarea from "./items/Textarea"
import Button from "../common/Button"
import GridTwo from "../common/GridTwo"
import { H2, H3 } from "../typography/Headings"
import P from "../typography/P"
import Select from "./items/Select"
import Checkbox from "./items/Checkbox"
import GridFour from "../common/GridFour"

function FotoreisenAnfragen() {
  const data = useStaticQuery(graphql`
    {
      zeitschriften: allPublikationen(
        filter: { art: { eq: "Zeitschriften" } }
      ) {
        nodes {
          title
        }
      }
    }
  `)

  const [asGift, setAsGift] = useState(false)

  const handleGift = e => {
    setAsGift(e.target.value)
  }
  return (
    <>
      <div className="max-w-2xl">
        <H2 className="mb-5">Einzelheftbestellung</H2>
      </div>
      <form
        name="Einzelheftbestellung"
        method="POST"
        data-netlify="true"
        action="/anfrage-gesendet"
        className="space-y-4"
      >
        <input type="hidden" name="form-name" value="Einzelheftbestellung" />
        <div>
          <H3 className="mb-5">Ausgaben</H3>
          <GridFour>
            {data.zeitschriften.nodes.map((ausgabe, i) => {
              return (
                <Checkbox
                  label={ausgabe.title}
                  id={`${ausgabe.title}-${i}`}
                  name={ausgabe.title}
                  key={i}
                />
              )
            })}
          </GridFour>
        </div>
        <div className="max-w-2xl space-y-5">
          <H3>Preise</H3>
          <P>
            Einzelpreis: 7,00 Euro pro Ausgabe, ab Ausgabe 1/2018 8,50 Euro pro
            Ausgabe
          </P>
          <H3>Versandkostenhinweis</H3>
          <P>Die Versandkosten betragen:</P>
          <ul className="space-y-5 list-disc list-inside">
            <li className="list-item">für ein Heft 1,50 Euro,</li>
            <li className="list-item">für zwei Hefte 3,00 Euro</li>
            <li className="list-item">ab drei Heften 6,00 Euro</li>
            <li className="list-item">
              Für unsere Abonnenten und für Bestellungen mit einem Bestellwert
              ab 60 Euro berechnen wir keine Versandkosten (nur innerhalb
              Deutschlands)
            </li>
            <li className="list-item">
              Bei Bestellungen aus dem Ausland werden entsprechende
              Versandkosten in das jeweilige Land berechnet
            </li>
          </ul>
        </div>
        <div className="max-w-2xl space-y-5">
          <Select
            label="Die Einzelheftbestellung soll"
            id="fuer"
            name="Die Einzelheftbestellung"
            required
            options={["für mich selbst sein", "ein Geschenk sein"]}
            onChange={handleGift}
          />
        </div>
        <GridTwo>
          <div className="space-y-5">
            <H3>Meine Anschrift (Rechnungsanschrift)</H3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <Input
                type="text"
                label="Vorname"
                id="vorname"
                name="Vorname"
                placeholder="Max"
                autoComplete="given-name"
                required
              />
              <Input
                type="text"
                label="Nachname"
                id="nachname"
                name="Nachname"
                placeholder="Mustermann"
                autoComplete="family-name"
                required
              />
            </div>
            <Input
              type="text"
              label="Straße"
              id="strasse"
              name="Straße"
              placeholder="Musterstraße 17"
              autoComplete="street-address"
              required
            />
            <Input
              type="text"
              label="PLZ"
              id="plz"
              name="PLZ"
              inputMode="numeric"
              placeholder="12345"
              autoComplete="postal-code"
              required
            />
            <Input
              type="text"
              label="Ort"
              id="ort"
              name="Ort"
              placeholder="Musterstadt"
              autoComplete="address-level2"
              required
            />
            <Input
              type="text"
              label="Land"
              id="land"
              name="Land"
              placeholder="Musterland"
              autoComplete="country-name"
              required
            />
            <Input
              type="email"
              label="E-Mail"
              id="email"
              name="E-Mail"
              placeholder="max@muster.de"
              autoComplete="email"
              required
            />
            <Input
              type="tel"
              label="Telefon"
              id="telefon"
              name="Telefon"
              placeholder="01234 567890"
              autoComplete="tel"
              inputMode="numeric"
            />
          </div>

          <div
            className={`space-y-5 ${
              asGift === "ein-geschenk-sein" ? "" : "hidden"
            }`}
          >
            <H3>Anschrift des Beschenkten</H3>
            <div className="grid-cols-2 gap-4 sm:grid ">
              <Input
                type="text"
                label="Vorname des Beschenkten"
                id="vorname-secondary"
                name="Vorname des Beschenkten"
              />
              <Input
                type="text"
                label="Nachname des Beschenkten"
                id="nachname-secondary"
                name="Nachname des Beschenkten"
              />
            </div>
            <Input
              type="text"
              label="Straße des Beschenkten"
              id="strasse-secondary"
              name="Straße des Beschenkten"
            />
            <Input
              type="text"
              label="PLZ des Beschenkten"
              id="plz-secondary"
              name="PLZ des Beschenkten"
              inputMode="numeric"
              autoComplete="postal-code"
            />
            <Input
              type="text"
              label="Ort des Beschenkten"
              id="ort-secondary"
              name="Ort des Beschenkten"
              autoComplete="address-level2"
            />
            <Input
              type="text"
              label="Land des Beschenkten"
              id="land-secondary"
              name="Land des Beschenkten"
              autoComplete="country-name"
            />
          </div>
        </GridTwo>

        <Textarea label="Kommentar" id="kommentar" name="Kommentar" />

        <div className="max-w-2xl space-y-5">
          <Checkbox
            label="Datenschutzerklärung"
            id="datenschutz-einwilligung"
            name="Datenschutzerklärung"
            text="Es gilt der Datenschutz des Naturblick Verlags. Ihre persönlichen Daten benötigen wir zur Einrichtung und Verwaltung Ihres Abonnements. Ich stimme zu, dass meine Angaben aus dem Bestellformular zur Bearbeitung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an verlag@naturblick.com widerrufen."
            required
          />
          <Checkbox
            label="E-Mail-Marketing"
            id="e-mail-marketing-einwilligung"
            name="E-Mail-Marketing"
            text="Ich willige ein, dass mich Naturblick Verlag per E-Mail über die von ihm angebotenen Zeitschriften, Online-Angebote, Printprodukte, Veranstaltungen informiert. Meine Daten werden ausschließlich zu diesem Zweck genutzt. Eine Weitergabe an Dritte erfolgt nicht. Ich kann die Einwilligung jederzeit per E-Mail an info@naturblick.com widerrufen"
          />
        </div>

        <Button submit text="Anfrage senden" className="w-full" />
      </form>
    </>
  )
}

export default FotoreisenAnfragen
