import React from "react"
import { graphql } from "gatsby"
import Container from "../../../components/common/Container"
import Layout from "../../../components/layouts/Default"
import PageHeader from "../../../components/common/PageHeader"
import Breadcrumb from "../../../components/common/Breadcrumb"
import EinzelhefteAnfragen from "../../../components/forms/EinzelhefteAnfragen"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Einzelheftbestellung" desc="">
      <PageHeader title="Einzelheftbestellung" image={data.header} />
      <Breadcrumb
        pages={[
          { name: "Verlag", to: "/verlag/" },
          { name: "Zeitschriften", to: "/verlag/zeitschriften/" },
          { name: "Einzelheftbestellung", to: "/verlag/zeitschriften/einzelheftbestellung/" },
        ]}
      />

      <Container className="space-y-8 md:space-y-16">
        <div>
          <div className="mb-8 md:mb-16">
            <EinzelhefteAnfragen />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "pages/verlag/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: ATTENTION}, width: 1600)
      }
    }
    zeitschriften: allPublikationen(
      sort: { fields: date, order: DESC }
      filter: { art: { eq: "Zeitschriften" } }
    ) {
      nodes {
        title
        title_slug
      }
    }
  }
`
