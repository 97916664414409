import React, { Fragment } from "react"

const Select = ({
  label,
  id,
  name,
  required,
  onChange,
  onKeyDown,
  asFragment,
  className,
  options,
}) => {
  let Tag = "div"
  if (asFragment) {
    Tag = Fragment
  }

  return (
    <Tag>
      {label && (
        <label className={`block text-xs mb-1`} htmlFor={id}>
          {label}
          {required && "*"}
        </label>
      )}

      <select
        className={`w-full p-4 leading-tight bg-white border appearance-none focus:outline-none focus:ring-1 focus:ring-black ${
          className ? className : ""
        }`}
        id={id}
        name={name}
        required={required}
        onChange={onChange}
        onKeyDown={onKeyDown}
      >
        {options.map((option, i) => {
          return (
            <option value={option.toLowerCase().replace(/\s/g, "-")} key={i}>
              {option}
            </option>
          )
        })}
      </select>
    </Tag>
  )
}

export default Select
